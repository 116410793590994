window.__tnt || (window.__tnt = {});
window.__tnt.pay || (window.__tnt.pay = {});

window.__tnt.pay.initPromises = [];
window.__tnt.pay.initialized = false;

window.__tnt.pay.method || (window.__tnt.pay.method = {});

/* tntPayment, runs when the form has initialized */
window.tntPayment = document.createEvent('Event');
tntPayment.initEvent('tnt-payment',true,true,{});

/* tntShowOfferModalTab, runs when the modal displays a tab within it */
window.tntShowOfferModalTab = document.createEvent('Event');
tntShowOfferModalTab.initEvent('tnt-show-offer-modal-tab',true,true,{});

window.tntPaymentSubmitted = document.createEvent('Event');
tntPaymentSubmitted.initEvent('tnt-payment-submitted', true, true, {});

window.tntPaymentSuccess = document.createEvent('Event');
tntPaymentSuccess.initEvent('tnt-payment-success', true, true, {});

window.tntPaymentError = document.createEvent('Event');
tntPaymentError.initEvent('tnt-payment-error', true, true, {});

/* Handle messages passed from payment iframe */
window.addEventListener('message', function(ev){
    var paymentFrameElements = document.querySelectorAll('iframe.spp-payment-frame');

    if (ev.data?.type == "SPREEDLY_TOKEN" && ev.data.token) {
        if(paymentFrameElements) {
            paymentFrameElements.forEach(frame => {
                if (frame.dataset.paymentType = "spp") {
                    var frameContentWindow = frame.contentWindow,
                        pf = null;

                    if (frame.dataset.paymentForm) pf = document.getElementById(frame.dataset.paymentForm);

                    if (pf && frame.contentWindow === ev.source) {
                        const data = new URLSearchParams();

                        data.append('tnpay-token', ev.data.token.token);
                        data.append('tnpay-year', ev.data.token.year);
                        data.append('tnpay-month', ev.data.token.month);
                        data.append('tnpay-last4', ev.data.token.last_four_digits);
                        data.append('tnpay-name', ev.data.token.full_name);

                        __tnt.pay.handlePurchaseSubmit(pf, data);
                    }
                }
            });
        }
    } else if (ev.data?.type == 'FORM_VALIDATION') {
        paymentFrameElements.forEach(frame => {
            if (frame.contentWindow === ev.source) {
                if (ev.data.isValid) {

                frame.dataset.isValid = "true";
                } else {

                frame.dataset.isValid = "false";
                }

                var pf = null;
                if (frame.dataset.paymentForm) pf = document.getElementById(frame.dataset.paymentForm);
                if (pf) __tnt.pay.tntTosEnableSubmit(pf);
            }
        });

    } else if (ev.data?.type == 'SPREEDLY_ERROR') {
        paymentFrameElements.forEach(frame => {
            if (frame.contentWindow === ev.source) {
                var pf = null,
                    errorEl = null,
                    errorMessageEl = null;

                if (frame.dataset.paymentForm) pf = document.getElementById(frame.dataset.paymentForm);

                if (pf && pf.dataset.errorElement) errorEl = document.getElementById(pf.dataset.errorElement);

                if (errorEl) {
                    errorMessageEl = errorEl.querySelector('.error-message');
                    
                    if (errorMessageEl) {
                        errorMessageEl.innerText = '';
                    
                        ev.data.errors.forEach(error => {
                            console.error(error.message);
                            
                            var errorOutput = document.createElement('div');
                            
                            errorOutput.innerText = error.message;
                            errorMessageEl.append(errorOutput);
                        });
                    }
                    
                    errorEl.classList.remove('fade');
                    errorEl.hidden = false;
                    errorEl.scrollIntoView({block:'center'});
                }
            }
        });
    } else if (ev.data?.type == 'FRAME_HEIGHT') {
        paymentFrameElements.forEach(frame => {
            if (frame.contentWindow === ev.source) {
                frame.style.height = ev.data.height + 'px';
            }
        });
    }
});

/*
 * Clear Selected Payment Method
 *
 * @DESC Resets the users payment method selections
 */
__tnt.pay.clearPaymentMethod = function(pf){
    if(pf){
        var payMethodElement = pf.querySelector('.tn-pay-method-output'),
            payMethodAltElement = pf.querySelector('.tn-pay-method-alt'),
            payMethodNewCard = pf.querySelector('.tn-pay-method-new-card'),
            selectedPayMethods = null,
            selectedAltPayMethods = null;
         
        if (payMethodElement) { 
            selectedPayMethods = payMethodElement.querySelectorAll('.wallet-btn');
                
            if (selectedPayMethods) { selectedPayMethods.forEach(function(selectedPayMethod){ selectedPayMethod.classList.remove('tn-pay-method-selected') }) }
        }
            
        if (payMethodAltElement) {
            selectedAltPayMethods = payMethodAltElement.querySelectorAll('.wallet-btn');
    
            if (selectedAltPayMethods) { selectedAltPayMethods.forEach(function(selectedAltPayMethod){ selectedAltPayMethod.classList.remove('tn-pay-method-selected') }) }
        }
            
        if (payMethodNewCard) { payMethodNewCard.classList.remove('tn-pay-method-selected') }
        
        if (pf.dataset.paymentMethod) {
            pf.removeAttribute('data-payment-method');
        }

        if(pf['tnpay-token']) pf['tnpay-token'].value = "";
        if(pf['use_stored']) pf['use_stored'].value = "";
    }
}

/*
 * Update display of Payment Methods
 * 
 * @DESC shows / hides the pay card entry if multiple payment options are available
 */
__tnt.pay.updatePaymentMethodDisplay = function(pf) {
    if (pf) {
        var payMethodElement = pf.querySelector('.tn-pay-method-output'),
            payMethodSelect = pf.querySelector('.tn-pay-method-select'),
            payMethodAltElement = pf.querySelector('.tn-pay-method-alt'),
            payCardEntry = pf.querySelector('.tn-pay-card-entry');

        if (
            ((payMethodElement && payMethodElement.querySelector('.wallet-btn')) || 
            (payMethodAltElement && payMethodAltElement.querySelector('.wallet-btn'))) &&
            payCardEntry
        ){
            payMethodSelect.hidden = false;
            payCardEntry.hidden = true
        } else if (payCardEntry) {
            payMethodSelect.hidden = true;
            payCardEntry.hidden = false

        }
    }
}

/*
 * Set Payment Method
 *
 * @DESC Set the method being used to pay
 */
__tnt.pay.setPaymentMethod = function(pf, methodName, methodButtonElement, tokenData){
    __tnt.pay.clearPaymentMethod(pf);

    try {
        var cardEntry = null;

        if (pf) cardEntry = pf.querySelector('.tn-pay-card-entry');
        if (pf && methodName) pf.dataset.paymentMethod = methodName;

        if(tokenData && tokenData.id) {
            if(pf['tnpay-token']) pf['tnpay-token'].value = tokenData.id;
            if(pf['use_stored']) pf['use_stored'].value = true;
        }

        if (methodButtonElement) { methodButtonElement.classList.add('tn-pay-method-selected') }
        if (cardEntry && methodButtonElement && !methodButtonElement.classList.contains('tn-pay-method-new-card')) cardEntry.hidden = true;
    } catch(e){ console.warn('Unable to set payment method', e) }

    __tnt.pay.tntTosEnableSubmit(pf);
}

/*
 * Validate Form
 *
 * @DESC Should observe the relevant required form inputs and inform the user when something is wrong
 */
__tnt.pay.validateForm = function(pf){
    /* if form validation has not yet been set up, set up form validation */
    if (pf && !pf.classList.contains('has-form-validation')) {
        pf.classList.add('has-form-validation');

        $(pf).validate({
            onkeyup: false,
            errorClass: 'has-error text-danger',
            errorElement: 'span',
            validClass: 'has-success text-success',
            highlight: function(element, errorClass, validClass) {
                $(element).parents('.form-group').addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).parents('.form-group').removeClass(errorClass).addClass(validClass);
            },
            rules: {
                password_verify: {
                    equalTo: pf.password,
                    hasSequentialNumbers: true,
                    hasRepeatingChars: true,
                    minlength: 8
                }
            },
            messages: {
                password_verify: {
                    equalTo: "The password you entered doesn't match.",
                    minlength: "Passwords must be at least 8 characters long."
                }
            }
        });

    /* if form validation has been initialized, reset the plugin and clear any errors */
    } else if (pf && pf.classList.contains('has-form-validation')) {
        $(pf).validate().resetForm();

        var hasErrors = pf.querySelectorAll('.has-error');

        if(hasErrors){
            hasErrors.forEach(function(errorEl){
                errorEl.classList.remove('has-error');
                errorEl.classList.remove('text-danger');
            });
        }
    }
}

/* 
 * Get Location
 *
 * @DESC checks the location information and sets tax data
 */
__tnt.pay.getLocation = function(oForm){
// Gather location inputs
    var paymentZip = oForm.querySelector('input[name=postal_code]'),
        billLocState = oForm.querySelector('select[name=postal-state]'),
        billLocZip = oForm.querySelector('input[name=postal-postcode]'),
        billLocCountry = oForm.querySelector('select[name=postal-country]'),
        billLocAddress = oForm.querySelector('input[name=postal-address]'),
        billLocCity = oForm.querySelector('input[postal-city]');

    try {
        if (typeof paymentZip === 'undefined') {
            // Create hidden postal_code input
            var paymentZip = document.createElement('input');
            
            paymentZip.setAttribute('type', 'hidden');
            paymentZip.setAttribute('name', 'postal_code');
            paymentZip.setAttribute('value', billLocZip.value);

            oForm.appendChild(paymentZip);
        } else if (!paymentZip.value || paymentZip.value == null) {
            // Populate empty postal_code input
            paymentZip.value = billLocZip.value;
        } 
    } catch(e) { console.log(e) }

    try {
        // Ensure purchaser inputs are supplied for gift transactions
        if (oForm.dataset.purchaseType == 'gift') {
            // Get purchaser inputs
            let purchaserState = oForm.querySelector('input[name=purchaser_state]'),
                purchaserZip = oForm.querySelector('input[name=purchaser_zip]'),
                purchaserCountry = oForm.querySelector('input[name=purchaser_country]'),
                purchaserAddress = oForm.querySelector('input[name=purchaser_address]'),
                purchaserCity = oForm.querySelector('input[name=purchaser_city]');

            // Create hidden purchaser input fields if they don't exist
            if (purchaserState === null) {
                purchaserState = document.createElement('input');

                purchaserState.setAttribute('type', 'hidden');
                purchaserState.setAttribute('name', 'purchaser_state');
                purchaserState.value = billLocState.value;

                oForm.appendChild(purchaserState);
            }

            if (purchaserZip === null) {
                purchaserZip = document.createElement('input');

                purchaserZip.setAttribute('type', 'hidden');
                purchaserZip.setAttribute('name', 'purchaser_zip');
                purchaserZip.value = billLocZip.value;

                oForm.appendChild(purchaserZip);
            }

            if (purchaserCountry === null) {
                purchaserCountry = document.createElement('input');

                purchaserCountry.setAttribute('type', 'hidden');
                purchaserCountry.setAttribute('name', 'purchaser_country');
                purchaserCountry.value = billLocCountry.value;

                oForm.appendChild(purchaserCountry);
            }

            if (purchaserAddress === null) {
                purchaserAddress = document.createElement('input');

                purchaserAddress.setAttribute('type', 'hidden');
                purchaserAddress.setAttribute('name', 'purchaser_address');
                purchaserAddress.value = billLocAddress.value;

                oForm.appendChild(purchaserAddress);
            }

            if (purchaserCity === null) {
                purchaserCity = document.createElement('input');

                purchaserCity.setAttribute('type', 'hidden');
                purchaserCity.setAttribute('name', 'purchaser_city');
                purchaserCity.value = billLocCity.value;

                oForm.appendChild(purchaserCity);
            }
        }
    } catch(e) { console.log(e) }
}

/*
 * Enable Submit Button
 *
 * @DESC toggles the submit button based on the TOS checkmark
 */
__tnt.pay.tntTosEnableSubmit = function(pf){
    var tos = null,
        paymentButton = null,
        paymentFrame = null,
        paymentMethod = null;

    if (pf) {
        tos = document.getElementById(pf.dataset.termsOfService);
        paymentButton = document.getElementById(pf.dataset.submitButton);
        paymentFrame = pf.querySelector('iframe.spp-payment-frame');
        paymentMethod = pf.dataset.paymentMethod;
    }

    if ((paymentMethod != null || paymentFrame.dataset.isValid == "true") && paymentButton && tos && tos.checked) {
        paymentButton.disabled = false;
        
        __tnt.subscription.offers.logCheckoutStepViewed({
            step: __tnt.subscription.offers.getPurchaseStep('tos'),
            label: 'Agreed to terms of service'
        });
        __tnt.subscription.offers.logCheckoutStepCompleted({
            step: __tnt.subscription.offers.getPurchaseStep('tos'),
            label: 'Agreed to terms of service'
        });
    } else if (paymentButton) {
        paymentButton.disabled = true;
    }
}

/*
 * Enable Form for Known User
 *
 * @DESC Modifies display of the purchase form and federated authentication form based on user status
 */
__tnt.pay.enableFormForKnownUser = function(pf, tntPay) {
    var pfLogin = pf.querySelector('.tnt-purchase-login'),
        afWrapper = tntPay.querySelector('.tnt-payment-federated-auth'),
        modalEl = document.getElementById('access-offers-modal'),
        modalDialogContainer = null,
        activeTab = null;

    if (pf) {
        pf.classList.remove("not-logged-in-form-disabled");
        pf.hidden = false;
    }

    if(afWrapper){ afWrapper.hidden = true }

    if(modalEl){
        modalDialogContainer = modalEl.querySelector('.modal');

        if(modalDialogContainer && modalDialogContainer.classList.contains('in')){
            activeTab = modalDialogContainer.querySelector('.modal-content.active');

            if(activeTab){
                activeTab.classList.remove('modal-scrollable');
                activeTab.removeAttribute('data-scrollable');
            }
        }

        tntShowOfferModalTab.details = {};
        tntShowOfferModalTab.details.tab = activeTab;
        tntShowOfferModalTab.details.resizeTab = true;
        window.dispatchEvent(tntShowOfferModalTab);
    }
}

/*
 * Clear Federated Authentication
 *
 * @DESC clears any values entered into the federated authentication form
 */ 
__tnt.pay.clearFederatedAuth = function(af){
    if (af && af.email) {
        af.email.value = "";
    }
}

/*
 * User Selected to Continue with Email
 *
 * @DESC Uses data from the federated authentication form to populate the purchase form
 */
__tnt.pay.selectedContinueWithEmail = function(pf, af, tntPay) {
    var emailAddressEl = af.querySelector('input[name=email]'),
        emailAddress = "";

    if(emailAddressEl){ emailAddress = emailAddressEl.value }

    if(emailAddress != ""){
        pf.querySelector('input[name=email]').value = emailAddress;
        pf.dataset.continueWithEmail = 'true';
        __tnt.pay.enableFormForKnownUser(pf, tntPay);
    }
    
    return false;
};

/*
 * Handle Purchase Form Submit
 * 
 * @DESC Handles submission of the payment form
 */
__tnt.pay.handlePurchaseSubmit = function (pf, oData) {
    tntPaymentSubmitted.details = {};
    pf.dispatchEvent(tntPaymentSubmitted);

    // Set location fields
    __tnt.pay.getLocation(pf);

    // Endpoint
    var purchaseEndpoint = pf.action;

    grecaptcha.enterprise.ready(function(){
        grecaptcha.enterprise.execute(pf.dataset.captchaKey, {action: 'subscribe'}).then(function(vkToken){
            if (!oData) oData = new URLSearchParams();

            oData.append('recaptcha_token', vkToken);

            for (const aPair of new FormData(pf)) {
                if (!oData.has(aPair[0])) oData.append(aPair[0], aPair[1]);
            }

            fetch(purchaseEndpoint, {
                method: 'post',
                credentials: 'include',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: oData
            }).then(function(oResp){
                oPayload = oResp.json().then(function(oPayload){
                    if (oPayload.transaction_id || (pf.dataset.purchaseType == "gift" && oPayload.success == true)){
                        var oProductData = __tnt.subscription.offers.getProduct(),
                            date = new Date();

                        tntPaymentSuccess.details = {};

                        if(oProductData){
                            tntPaymentSuccess.details.payload = oPayload;
                            tntPaymentSuccess.details.transactionData = {
                                transaction_id: oPayload.transaction_id,
                                service_name: oProductData.name,
                                service_rate: oProductData.variant,
                                rate_price: oProductData.price,
                                start_date: date.getTime(),
                                tax: oPayload.total_tax_rate,
                                currency: oProductData.currency,
                                purchaseType: pf.dataset.purchaseType
                            };
                        } else { console.warn("Product data not found"); }

                        pf.dispatchEvent(tntPaymentSuccess);
                        
                        /* LOG PURCHASE COMPLETE EVENT */
                        __tnt.subscription.offers.logOrderCompleted(oPayload.transaction_id);

                    } else {
                        tntPaymentError.details = {};
                        
                        if (oPayload.success && oPayload.message == "Payment method added and Transaction updated"){

                        } else if (oPayload.message) {
                            tntPaymentError.details.message = oPayload.message;

                        } else {
                            tntPaymentError.details.message = "An error occurred";

                        }

                        pf.dispatchEvent(tntPaymentError);

                    }
                });
            });

        });
    });

};

/*
 * Register Credit Card Payments
 */
__tnt.pay.method.newCard = {
    name: "newCard",
    methodString: "New card",
    init: function(pf) {
        var addNewCardEl = pf.querySelector('.tn-pay-method-new-card'),
            cardEntryEl = pf.querySelector('.tn-pay-card-entry'),
            cardWidgetWrapper = pf.querySelector('.tn-pay-card-wrapper'),
            cardDataSource = "";

        if(addNewCardEl && cardEntryEl){
            addNewCardEl.addEventListener('click', function(ev){
                __tnt.pay.clearPaymentMethod(pf);

                pf.removeAttribute('data-payment-method');
                cardEntryEl.hidden = false;
                // this will also need to clear selected payment methods

                addNewCardEl.classList.add('tn-pay-method-selected');
            });
        }

        if (cardWidgetWrapper) {
            cardWidgetWrapper.innerHTML = "";

            var pif = document.createElement('iframe');

            pif.src = "/tncms/pay/add_card/?handler=" + cardDataSource + "&style=flex-spp";
            pif.width = "100%";
            pif.scrolling = "no";
            pif.frameborder = "0";
            pif.style = "overflow: hidden; min-height: 187px;"
            pif.className = "spp-payment-frame";
            pif.dataset.paymentForm = pf.id;
            pif.dataset.paymentType = "spp";

            cardWidgetWrapper.appendChild(pif);
        }
    },
    submitPayment: function(pf) {
        var pif = pf.querySelector('iframe.spp-payment-frame'),
            pifw = null;

        if (pif) {
            pifw = pif.contentWindow;

            if (pifw) {
                pifw.postMessage({"action":"tokenize"});
            }
        }
    }
};

document.addEventListener("DOMContentLoaded", function (event) {
    var tntPaymentWrapper = document.querySelectorAll('.tnt-payment');

    if(tntPaymentWrapper && tntPaymentWrapper.length){

        tntPaymentWrapper.forEach(function(tntPay){

            /* Payment Form Setup */
            var pf = tntPay.querySelector('form.tnt-payment-form'),
                af = tntPay.querySelector('form.tnt-payment-federated-auth-form'),
                tos = null,
                paymentButton = null,
                copyToggle = null,
                afEmail = null,
                pfEmail = null,
                pfName = null,
                pfDelivery = null;

            /* Payment Form */
            if(pf){
                tos = pf.tos_confirm;
                paymentButton = pf.payment_button;
                copyToggle = pf.querySelector('.same-info');

                /* Payment Form Validation */
                __tnt.pay.validateForm(pf);

                /* automatically copy location information */
                if (copyToggle) {
                    copyToggle.addEventListener("change", function(event){
                        if(event.target.checked){
                            var billingForm = pf.querySelector('.tnt-billing-location'),
                                deliveryForm = pf.querySelector('.tnt-delivery-location');
                                
                            if(billingForm && deliveryForm){
                                try {
                                    deliveryForm.querySelector('input[name=address]').value = billingForm.querySelector('input[name="postal-address"]').value;
                                    deliveryForm.querySelector('input[name=municipality]').value = billingForm.querySelector('input[name="postal-city"]').value;
    
                                
                                    deliveryForm.querySelector('select[name=region]').value = billingForm.querySelector('select[name="postal-state"]').value;
                                    deliveryForm.querySelector('select[name=country]').value = billingForm.querySelector('select[name="postal-country"]').value;
                                    deliveryForm.querySelector('input[name=postal_code]').value = billingForm.querySelector('input[name="postal-postcode"]').value;
                                
                                } catch (e) { /* Address, city not collected */ }
        
                                /** Run tax calculation */
                                __tnt.subscription.offers.calculateTax(pf, pf.postal_code, pf.country);
                            }
                        }
                    });
                }
                
                /* Checkout Step - entering the email */
                pfEmail = pf.querySelector('input[name="email"]');
                if (pfEmail) {
                    pfEmail.addEventListener('focus', function(ev) {
                        __tnt.subscription.offers.logCheckoutStepViewed({
                            step: __tnt.subscription.offers.getPurchaseStep('email'),
                            label: 'Entering email address'
                        });
                    });

                    pfEmail.addEventListener('blur', function(ev) {
                        if (pfEmail.value) {
                            __tnt.subscription.offers.logCheckoutStepCompleted({
                                step: __tnt.subscription.offers.getPurchaseStep('email'),
                                label: 'Email address defined'
                            });
                        }
                    });
                }

                /* Checkout Step - entering the delivery location */
                pfDelivery = pf.querySelector('.delivery-location-required');
                if (pfDelivery) {
                    var locationFields = pfDelivery.querySelectorAll('input[type=text], input[type=tel], select');
                    locationFields.forEach(function(el) {
                        // Set lengths for inputs that are pre-filled
                        if (el.value.length > 0) {
                            __tnt.subscription.offers.locationInputLengths[el.name] = el.value.length;
                        }

                        el.addEventListener('focus', function(ev) {
                            __tnt.subscription.offers.logCheckoutStepViewed({
                                step: __tnt.subscription.offers.getPurchaseStep('location'),
                                label: 'Entering location information'
                            });
                        });

                        el.addEventListener('blur', function(ev) {
                            __tnt.subscription.offers.locationInputLengths[ev.target.name] = ev.target.value.length;

                            if (__tnt.subscription.offers.locationInputsEntered()) {
                                __tnt.subscription.offers.logCheckoutStepCompleted({
                                    step: __tnt.subscription.offers.getPurchaseStep('location'),
                                    label: 'Location information defined'
                                });
                            }
                        });
                    });
                }

                /* Checkout Step - entering the cardholder name */
                pfName = pf.querySelector('input[name="tnpay-name"]');
                if (pfName) {
                    pfName.addEventListener('focus', function(ev) {
                        __tnt.subscription.offers.logCheckoutStepViewed({
                            step: __tnt.subscription.offers.getPurchaseStep('cardholder_name'),
                            label: 'Entering cardholder name',
                            paymentMethod: 'Credit Card'
                        });

                    });

                    pfName.addEventListener('blur', function(ev) {
                        if (pfName.value) {
                            __tnt.subscription.offers.logCheckoutStepCompleted({
                                step: __tnt.subscription.offers.getPurchaseStep('cardholder_name'),
                                label: 'Cardholder name defined',
                                paymentMethod: 'Credit Card'
                            });

                            __tnt.subscription.offers.ccNameLength = pfName.value.length;

                            if (__tnt.subscription.offers.ccInputsEntered()) {
                                __tnt.subscription.offers.logPaymentInfoEntered({
                                    step: __tnt.subscription.offers.getPurchaseStep('payment_info'),
                                    paymentMethod: 'Credit Card'
                                });
                            }
                        }
                    });
                }
            }

            /* Federated Auth Form */
            if (af) {
                afEmail = af.email;

                if (afEmail) {
                    afEmail.addEventListener('focus', function(ev) {
                        __tnt.subscription.offers.logCheckoutStepViewed({
                            step: __tnt.subscription.offers.getPurchaseStep('email'),
                            label: 'Entering email address'
                        });
                    });

                    afEmail.addEventListener('blur', function(ev) {
                        if (afEmail.value) {
                            __tnt.subscription.offers.logCheckoutStepCompleted({
                                step: __tnt.subscription.offers.getPurchaseStep('email'),
                                label: 'Email address defined'
                            });
                        }
                    });
                }
            
                af.addEventListener('submit',function(ev){
                    __tnt.pay.selectedContinueWithEmail(pf, af, tntPay);

                });
            } else {
                __tnt.pay.enableFormForKnownUser(pf, tntPay);

            }

            if(tos && paymentButton && pf){
                tos.addEventListener('change', function(ev){
                    __tnt.pay.tntTosEnableSubmit(pf);
                });
            }

            /* Purchase Specific Init - Happens Every Time */
            pf.addEventListener('tnt-purchase', function(ev){
                var pf = this,
                    afWrapper = null,
                    af = null,
                    errorAlert = null,
                    errorMessage = null;
                
                if (pf && pf.parentElement) {
                    afWrapper = pf.parentElement.querySelector('.tnt-payment-federated-auth');
                    errorAlert = pf.parentElement.querySelector('.tnt-payment-error');
                    errorMessage = pf.parentElement.querySelector('.tnt-payment-message');
                    
                    if (afWrapper) { af = afWrapper.querySelector('form.tnt-payment-federated-auth-form') }
                    if (errorAlert) { errorAlert.hidden = true }
                    if (errorMessage) { errorMessage.hidden = true }
                }
                
                __tnt.pay.clearFederatedAuth(af);
                __tnt.pay.validateForm(pf);
                __tnt.pay.clearPaymentMethod(pf);

                pf.FormInitPromises = [];

                if(__tnt.pay.method){
                    var payMethods = Object.keys(__tnt.pay.method);
                    
                    if(payMethods.length){
                         payMethods.forEach(function(method){
                             if(typeof __tnt.pay.method[method].init == "function"){
                                 __tnt.pay.method[method].init(pf);
                             }
                         });
                    }
                }

                pf.hidden = true;

                pf.FormInitPromises.push(__tnt.loadScript('https://www.google.com/recaptcha/enterprise.js?render='+pf.dataset.captchaKey));

                if (pf.dataset.appleMerchantName && pf.dataset.appleMerchantId) {
                    var appleScriptPromise = __tnt.loadScript('https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js');
                    pf.FormInitPromises.push(appleScriptPromise);

                }

                /* All Init Promises Completed */
                Promise.all(pf.FormInitPromises).then(function() {
                    __tnt.pay.updatePaymentMethodDisplay(pf);

                    var spreedlyLoading = pf.querySelector('.tn-pay-card-loading'),
                        spreedlyWrapper = pf.querySelector('.tn-pay-card-wrapper');

                    if(!af){ __tnt.pay.enableFormForKnownUser(pf, tntPay) }
                    if(spreedlyLoading && spreedlyWrapper){
                        spreedlyLoading.hidden = false;
                        spreedlyWrapper.hidden = true;
                    }

                    if(pf){
                        pf.classList.add('spreedly-initialized');

                        if(spreedlyLoading && spreedlyWrapper){
                            spreedlyLoading.hidden = true;
                            spreedlyWrapper.hidden = false;
                        }

                    } else {
                        if(spreedlyLoading && spreedlyWrapper){
                            spreedlyLoading.hidden = true;
                            spreedlyWrapper.hidden = false;
                        }
                    }

                    if(afWrapper && __tnt.user.authToken == null && !pf.email.value && pf.dataset.authRequired != 'false'){
                        afWrapper.hidden = false;
                        pf.hidden = true;

                    } else if(afWrapper && pf && tntPay) {
                        afWrapper.hidden = true;
                        __tnt.pay.enableFormForKnownUser(pf, tntPay);
                    }

                });
            });

            /* 
             * Form Submit
             */
            pf.addEventListener('submit', function(ev){
                ev.preventDefault();

                var paymentMethod = 'newCard',
                    requiredFields = {},
                    sError = "";

                if(pf){
                    if (pf.dataset.paymentMethod) paymentMethod = pf.dataset.paymentMethod;

                    /* Payment Method Submit Handler */
                    if (paymentMethod && __tnt.pay.method[paymentMethod] && typeof __tnt.pay.method[paymentMethod].submitPayment == "function") {
                        __tnt.pay.method[paymentMethod].submitPayment(pf);
                    }
                }

                return false;
            });

            /*
             * Payment Success
             */
            pf.addEventListener('tnt-payment-success', function(ev){
                __tnt.user.loginSuccess = true;
                __tnt.user.purchasedAccess = true;
                    
                if (__tnt.user && typeof __tnt.user.updateLoggedIn == "function") {
                    __tnt.user.updateLoggedIn();
                } else if (__tnt.user && typeof __tnt.user.update == "function") {
                    __tnt.user.update();
                }
            });

            /*
             * Payment Error
             */
            pf.addEventListener('tnt-payment-error', function(ev){
                var pf = this,
                    errorAlert = null,
                    errorAlertMessage = null,
                    errorButton = document.createElement('a'),
                    forgotText = null;

                if (pf) { errorAlert = pf.parentElement.querySelector('.tnt-payment-error') }

                if(errorAlert){
                    errorAlertMessage = errorAlert.querySelector('.error-message');

                    if(ev.details && ev.details.message){
                        if (errorAlertMessage && ev.details.message) { errorAlertMessage.innerHTML = ev.details.message }
                        if (ev.details.message == "Invalid password or account does not exist") {

                            if(errorAlertMessage){
                                forgotText = errorAlertMessage.dataset.forgotText;

                                if(forgotText){ errorButton.innerHTML = forgotText }
                            } else {
                                errorButton.innerHTML = "Forgot your password?";
                            }
                            errorButton.classList.add("alert-link", "btn-block");
                            errorButton.href = "javascript:void(0)";
                            errorButton.addEventListener('click',function(ev){
                                var modal = document.getElementById('access-offers-modal'),
                                    modalTabForgot = document.getElementById('user-modal-tab-forgot');

                                __tnt.subscription.access.showTab(modalTabForgot, modal);
                            });

                            errorAlertMessage.appendChild(errorButton);
                        }

                        errorAlert.hidden = false;
                        errorAlert.classList.remove('fade');
                    }
                }

            });


            /*
             * Update Purhcase Form base on User Status
             */
            window.addEventListener('tnt-user-status',function(ev){
                var tntPaymentWrapper = document.querySelectorAll('.tnt-payment');

                if(tntPaymentWrapper){
                    tntPaymentWrapper.forEach(function(tntPay){
                        var pf = tntPay.querySelector('form.tnt-payment-form'),
                            afWrapper = tntPay.querySelector('form.tnt-payment-federated-auth'),
                            pfLogin = null;
                        
                        if (pf) {
                            pfLogin = pf.querySelector('.tnt-purchase-login');

                            if(__tnt.user.authToken == null){
                                pf.classList.add("not-logged-in-form-disabled");
                            } else {
                                __tnt.pay.enableFormForKnownUser(pf, tntPay);
                            }
                        }
                    });
                }
            });

        }); // End TNT Payment Wrapper Loop
    }

    __tnt.user.update();

});
